body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    'Montserrat', 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-light-color: #9A8CEF;
  --primary-main-color: #452BE4;
  --primary-dark-color: #2608DC;
  --primary-secondary-color: #f50057;
  --contrast-text-color: #FFF;
  --error-main-color: #FD4F4F;
  --error-light-color: #F5B7B7;
  --primary-text-color: #000;
  --secondary-text-color: #e1e1e1;
  --background-default-color: #FAFCFA;
  --background-paper-color: #FFF;
  --divider-color: #E1E1E1;
  --shadow-main-color: #E5E5E5;
  --grey-200-color: rgba(225, 225, 225, 0.2);
}
.flag.ru {
  background-image: url(../public/icon/whiteflag.png)!important;
  background-size: contain!important;
  background-position: center!important;
}