.main-container {
    display: flex;
    align-items: flex-start;
    height: 100vh;
}

.create-company-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: 100vh;
}

.create-company-sidebar {
    padding-top: 40px;
    color: var(--contrast-text-color);
    box-sizing: border-box;
    height: 100vh;
    max-width: 450px;
    min-width: 400px;
    background-color: var(--primary-main-color);
}

.sidebar-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3;
    color: var(--secondary-text-color);
    box-sizing: border-box;
    height: 100vh;
    overflow-y: scroll;
    min-width: 280px;
    max-width: 280px;
    background-color: #333333;
        &::-webkit-scrollbar {
            width: 5px
       };
           &::-webkit-scrollbar-thumb {
            background: #e1e1e11a;
            border-radius: 5px;
    }
}

.logo-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 25px 0;
}

.create-team-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo > path {
    fill: #FFF;
}

.logo {
    color: var(--secondary-text-color);
    font-weight: 500;
    font-size: 24px;
    line-height: 1.286;
    margin-left: 26px;
}

.create-button-container {
    display: flex;
    justify-content: center;
}

.btn-more {
    display: flex!important;
    margin: auto!important;
}

.user-title {
  margin: 0 0 20px;
}

.accordion-list > .navbar-link {
    margin: 0 0 16px 20px;
}

.current-page {
    color: #ffffff;
    font-weight: 700;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-alert-container{
        background-color: white;
        width: 100%;
        max-width: 300px;
        padding: 1em;
        border-radius: 4px;
        overflow-y: auto;
        .modal-head-container {
            display: flex;
            justify-content: space-between;
        }
    }
    .modal-container {
        background-color: white;
        width: 100%;
        max-width: 450px;
        max-height: 700px;
        padding: 16px 24px;
        border-radius: 4px;
        overflow-y: auto;
        .modal-head-container {
            display: flex;
            justify-content: space-between;
        }
    }
    .modal-container-vacancies {
        background-color: white;
        width: 100%;
        max-width: 900px;
        max-height: 800px;
        padding: 1em;
        border-radius: 4px;
        overflow-y: auto;
        .modal-head-container {
            display: flex;
            justify-content: space-between;
        }
    }

    .reset-password-container {
        @extend .modal-container;
        width: 450px;
    }
}

.create-user-button {
    border: 1px solid black;
    background-color: white;
    padding: 8px 16px;
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    transition: 0.5s;

    &:hover {
        background-color: black;
        color: white;
    }
}

@media (max-width: 750px) {
    .icon {
        height: 36px;
        width: 36px;
    }

    .accordeon-icon {
        height: 32px;
        width: 32px;
    }

    .create-company-container {
        flex-direction: column;
    }

    .create-company-sidebar {
        overflow-y: hidden;
        width: 100%;
        padding: 48px 16px 32px 16px;
        height: unset;
        max-width: unset;
    }

    .modal {
        display: flex;
        justify-content: center;
        align-items: center;

        .modal-container {
            background-color: white;
            width: 100%;
            max-width: 315px;
            max-height: 500px;
            padding: 24px 16px;
            border-radius: 4px;
            overflow-y: auto;
            .modal-head-container {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
