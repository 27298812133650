@import "shared/loading/Loading.module.scss";
@import "components/navigations-section/sidebar/Sibebar";

* {
  list-style-type: none;
  outline: none;
}

a {
  color: var(--primary-text-color);
  text-decoration: none;
}

h1,
h2 {
  margin: 0;
}

.content-container {
  flex-grow: 1;
  background-color: #F8F9FF;
  overflow-y: auto;
  height: 100vh;
  max-width: 100vw;
  width: 100vw;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  &,
  &:hover,
  &:focus,
  &:active {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  }
}

@media (max-width: 750px) {
  .content-container {
    background-color: #FFF;
    height: calc(100vh - 84px);
  }
}
